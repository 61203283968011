import Vue from 'vue'
import { BUSINESS_CODE_MODE,LOGIN_CHECK_MESSAGE,userSignInService } from '@/service/user-service';
import Cookies from 'js-cookie';
import { mapMutations } from 'vuex'
import { Message,LoadingBar, Button, Input, Icon,Checkbox,RadioGroup,Radio } from 'view-design';
Vue.component('Button', Button);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Checkbox', Checkbox);
Vue.component('RadioGroup', RadioGroup);
Vue.component('Radio', Radio);
import { systemPublishEnum,systemPublishLogo } from '@/service/system-service'
const Login = {
    name:'Login',
    data(){
        return{
            loadingStatus:false, //loading 当前状态 请求发起时设为true 结束设为false ps:等待效果防止用户点击过快
            systemPublishMenu:systemPublishEnum, //配置演示状态枚举
            systemPublishLogo:systemPublishLogo, //配置演示状态
            userInfo:{
                accountStatus:'Enterprise', //账户状态 1企业账户 2 平台账户
                userName:'',
                password:'',
            }
        }
    },
    created() {
        if(this.$route.query.account === 'system'){
            this.userInfo.accountStatus = 'System'
        }else{
            this.userInfo.accountStatus = 'Enterprise'
        }
    },
    mounted() {
        window.addEventListener('keydown',this.onKeyDown)
    },
    methods:{
        ...mapMutations('userCenter',['SET_USER_INFO','SET_ACCOUNT_STATUS']),
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onSignInCheck()
            }
        },
        /**
         *  校验方法 通过之后开启防抖函数 调用登录方法
         *  @param account && password
         */
        onSignInCheck() {
            if(this.userInfo.userName === '' || this.userInfo.password === ''){
                Message.error('帐号或密码不能为空！')
                return
            }
            this.onSignIn().then()
        },
        onRegister() {
            Message.warning('暂未开放，请您关注灵云系统1.1版！');
        },
        /**
         * 登录方法
         * @returns {Promise<void>}
         */
        async onSignIn() {
            try {
                LoadingBar.start()
                this.loadingStatus = true
                let params = {
                    loginAccount:this.userInfo.userName,
                    password:this.userInfo.password,
                    background:this.userInfo.accountStatus
                }
                const { code,data } = await userSignInService(params)
                switch (code){
                    case BUSINESS_CODE_MODE.SUCCESS:  //业务成功
                        LoadingBar.finish()
                        console.log('finish')
                        Cookies.set('token',data.token,{expires:1});
                        console.log('token')
                        this.SET_USER_INFO(data)
                        this.SET_ACCOUNT_STATUS(this.userInfo.accountStatus)
                        console.log('vuex')
                        await this.$router.push('/');
                        console.log('跳转')
                        break;
                    case BUSINESS_CODE_MODE.USERNAME_OR_PASSWORD_FREE_ERROR: //用户名或密码错误
                        LoadingBar.error();
                        Message.error(LOGIN_CHECK_MESSAGE.USERNAME_OR_PASSWORD_FREE_ERROR);
                        break;
                    case 'USER_MOBILE_FORMAT_ERROR': //用户名或密码错误
                        LoadingBar.error();
                        Message.error('手机号格式有误！');
                        break;
                    case 'USER_NOT_AUTHORIZED_TO_LOGIN': //暂无权限
                        LoadingBar.error();
                        Message.error('您没有权限访问系统，请联系管理员！');
                        break;
                }
                this.loadingStatus = false
            }catch (error){
                LoadingBar.error()
                this.loadingStatus = false
                console.log(error)
            }
        }
    },
    destroyed() {
        window.removeEventListener('keydown',this.onKeyDown,false);
    }
}
export default Login
